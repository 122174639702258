/* ServiceDetail.css */

.service-detail {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.service-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.service-detail h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.service-detail div {
  text-align: left;
  line-height: 1.6;
}
