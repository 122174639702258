/* Header.css */

.header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-title {
  font-size: 1.5rem;
  margin: 0;
}

.nav {
  display: flex;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links li a:hover {
  text-decoration: underline;
}
