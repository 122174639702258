/* Footer.css */

.footer {
  padding: 1.5rem;
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer div {
  max-width: 800px;
  margin: 0 auto;
}
