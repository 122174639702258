/* HeroSection.css */

.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  background: linear-gradient(to right, #0073e6, #00bfff);
  color: #fff;
  text-align: center;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.cta-button {
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  color: #0073e6;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #f0f0f0;
}
