/* ContactForm.css */

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
}

.contact-form label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form button {
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #0073e6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.contact-form button:hover {
  background-color: #005bb5;
}
