/* Services.css */

.services-section {
  padding: 2rem;
  text-align: center;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.service-box {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-box img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.service-box h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.service-box p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

.view-more-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #0073e6;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.view-more-button:hover {
  background-color: #005bb5;
}
