/* ContactPage.css */

.contact-page {
  margin: 0 auto;
  text-align: center;
}

.contact-page h1 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.contact-page div {
  margin-bottom: 2rem;
}
