/* HomePage.css */

.home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-page > * {
  flex-shrink: 0;
}
